import { PUBLIC_VERCEL_ENV } from "$env/static/public";
import type { Site } from "@datadog/browser-core/cjs/domain/configuration";
import { datadogLogs } from "@datadog/browser-logs";

export function initDatadog({ clientToken, site }: { clientToken: string; site: Site }) {
  if (!clientToken || !site) {
    return;
  }
  datadogLogs.init({
    clientToken,
    site,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: "all",
    env: PUBLIC_VERCEL_ENV,
  });
}
