import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130')
];

export const server_loads = [0,2,6];

export const dictionary = {
		"/(public)": [~85],
		"/(public)/about-us": [86],
		"/(public)/add-deck": [87],
		"/(admin)/admin/extra-mana/add": [~19,[2,3]],
		"/(admin)/admin/orders/buyer/[order_id]": [~22,[2,4]],
		"/(admin)/admin/orders/order_issue_reports/[profile_id]": [~25,[2,4]],
		"/(admin)/admin/orders/order_issue_report": [~23,[2,4]],
		"/(admin)/admin/orders/order_issue_report/[id]": [~24,[2,4]],
		"/(admin)/admin/orders/ratings/[profile_id]": [~26,[2,4]],
		"/(admin)/admin/orders/seller-stats": [~27,[2,4]],
		"/(admin)/admin/orders/seller-stats/[profile_id]": [~28,[2,4]],
		"/(admin)/admin/orders/unfulfilled": [~29,[2,4]],
		"/(admin)/admin/orders/[seller_order_id]": [~20,[2,4]],
		"/(admin)/admin/orders/[seller_order_id]/report": [~21,[2,4]],
		"/(admin)/admin/payouts/edit": [~30,[2,5]],
		"/(admin)/admin/payouts/history": [~31,[2,5]],
		"/(admin)/admin/payouts/issue": [~32,[2,5]],
		"/(admin)/admin/payouts/pending": [~33,[2,5]],
		"/(admin)/admin/payouts/seller/[normalized_username]": [~34,[2,5]],
		"/(admin)/admin/supply/health": [~35,[2]],
		"/(admin)/admin/supply/health/[format]": [~36,[2]],
		"/(public)/affiliates": [88],
		"/api/docs/v1": [126],
		"/auth": [~127],
		"/auth/confirm": [~128],
		"/auth/signout": [129],
		"/(public)/browse_sealed": [89,[11]],
		"/(public)/cards": [92,[13]],
		"/(public)/cards/set/[set_code]/[[set_name]]": [93,[13,14]],
		"/(public)/card/[card_slug]": [90],
		"/(public)/card/[set_code]/[card_number]/[[card_name]]": [~91,[12]],
		"/(public)/cart": [94,[15]],
		"/(public)/cart/checkout": [95,[15]],
		"/(public)/daily": [~96],
		"/(public)/daily/rules": [97],
		"/(admin)/experiments/overrides": [37,[2]],
		"/(public)/lol": [98],
		"/(public)/mail": [99],
		"/(public)/mail/error": [101],
		"/(public)/mail/notfound": [102],
		"/(public)/mail/[template]/[content]": [~100],
		"/maintenance": [130],
		"/(public)/mass-entry-info": [103],
		"/(public)/mtg": [104],
		"/(public)/mtg/set/[set_code]": [105],
		"/(public)/mtg/set/[set_code]/[slug]": [106],
		"/(public)/optimizer": [107],
		"/(authed)/packing-slip/[order_id]": [38,[6]],
		"/(public)/privacy": [108],
		"/(authed)/profile/change-email": [39,[6,7]],
		"/(authed)/profile/change-password": [40,[6,7]],
		"/(authed)/profile/notifications": [~41,[6,7]],
		"/(public)/promo/[promo_id]": [~109],
		"/(public)/racist-depictions-policy": [110],
		"/(public)/redirect-after-login": [~111],
		"/(public)/reset-password": [~112],
		"/(public)/return-policy": [113],
		"/(public)/sealed/[set_code]/[slug]": [~114,[16]],
		"/(public)/seller-info": [115],
		"/(authed)/seller": [42,[6,8]],
		"/(authed)/seller/integrations": [43,[6,8,9]],
		"/(authed)/seller/integrations/crystalcommerce": [~44,[6,8,9]],
		"/(authed)/seller/integrations/manapool-api": [~45,[6,8,9]],
		"/(authed)/seller/integrations/shopify": [46,[6,8,9]],
		"/(authed)/seller/integrations/sortswift": [~47,[6,8,9]],
		"/(authed)/seller/inventory": [48,[6,8]],
		"/(authed)/seller/inventory/add/[[item_id]]": [49,[6,8]],
		"/(authed)/seller/inventory/edit/[item_id]": [50,[6,8]],
		"/(authed)/seller/inventory/import": [~51,[6,8]],
		"/(authed)/seller/orders": [52,[6,8]],
		"/(authed)/seller/orders/fulfilled": [55,[6,8]],
		"/(authed)/seller/orders/open": [56,[6,8]],
		"/(authed)/seller/orders/remediate/[report_id]": [~57,[6,8]],
		"/(authed)/seller/orders/[order_id]": [~53,[6,8]],
		"/(authed)/seller/orders/[order_id]/report": [~54,[6,8]],
		"/(authed)/seller/payouts": [~58,[6,8]],
		"/(authed)/seller/payouts/[payout_id]": [~59,[6,8]],
		"/(authed)/seller/pricing": [~60,[6,8]],
		"/(authed)/seller/profile": [61,[6,8]],
		"/(authed)/seller/sales": [62,[6,8]],
		"/(authed)/seller/sales/[id]": [63,[6,8]],
		"/(authed)/seller/stats": [~64,[6,8]],
		"/(authed)/seller/stats/order_issue_reports": [~65,[6,8]],
		"/(authed)/seller/stats/order_issue_reports/settings": [~66,[6,8]],
		"/(authed)/seller/stats/ratings": [~67,[6,8]],
		"/(authed)/settings": [68,[6,10]],
		"/(authed)/settings/addresses": [~69,[6,10]],
		"/(authed)/settings/affiliates": [70,[6,10]],
		"/(authed)/settings/billing": [71,[6,10]],
		"/(authed)/settings/change-email": [72,[6,10]],
		"/(authed)/settings/change-password": [73,[6,10]],
		"/(authed)/settings/credit": [~74,[6,10]],
		"/(authed)/settings/credit/[transaction_id]": [~75,[6,10]],
		"/(authed)/settings/extra-mana": [76,[6,10]],
		"/(authed)/settings/notifications": [77,[6,10]],
		"/(authed)/settings/orders": [78,[6,10]],
		"/(authed)/settings/orders/report/[order_id]": [~80,[6,10]],
		"/(authed)/settings/orders/suborder/[seller_order_id]": [~81,[6,10]],
		"/(authed)/settings/orders/[order_id]": [~79,[6,10]],
		"/(authed)/settings/referrals": [~82,[6,10]],
		"/(authed)/settings/saved_for_later": [83,[6,10]],
		"/(public)/set/all_sets": [117],
		"/(public)/set/[set_code]/[[slug]]": [116,[17]],
		"/(public)/shopify": [~119],
		"/(public)/shop/[normalized_username]": [118,[18]],
		"/(public)/signin": [~120],
		"/(public)/signup": [~121],
		"/(authed)/support": [~84,[6]],
		"/(public)/terms": [122],
		"/(public)/thank-you/[id]": [~123],
		"/(public)/top_cards": [~124],
		"/(public)/top_cards/[format]": [~125]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';