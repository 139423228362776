import { dev } from "$app/environment";
import {
  PUBLIC_AMPLITUDE_API_KEY,
  PUBLIC_DATADOG_CLIENT_TOKEN,
  PUBLIC_DATADOG_SITE,
  PUBLIC_SENTRY_DSN,
} from "$env/static/public";
import { initAmplitude } from "$lib/monitoring/amplitudeBrowser";
import { initDatadog } from "$lib/monitoring/datadogBrowser";
import { initSentry, wrapHandleError } from "$lib/monitoring/sentryBrowser";
import type { Site } from "@datadog/browser-core/cjs/domain/configuration";

initSentry(PUBLIC_SENTRY_DSN);
initAmplitude(PUBLIC_AMPLITUDE_API_KEY);
initDatadog({ clientToken: PUBLIC_DATADOG_CLIENT_TOKEN, site: PUBLIC_DATADOG_SITE as Site });

export const handleError = wrapHandleError(({ error, message }) => {
  if (dev) console.error(message, error);
  if (!(error instanceof Error)) {
    throw new Error(`Unknown error`, { cause: error });
  }
  return { message };
});

// Polyfills for client support on older browsers
import "core-js/stable/structured-clone";
import "core-js/stable/url/to-json";
